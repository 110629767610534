import React from "react";
import SolutionPage from "../../components/SolutionPage";

const data = {
  heroSection: {
    label: {
      title: 'LooprIQ Inspect', 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-inspect-label-logo.png', 
    }, 
    heading: [
      {
        text: '100% visual inspection', 
        textColor: 'blue-800'
      },
      {
        text: '& lower cost of quality', 
        textColor: 'black'
      }
    ], 
    content: "Identify defects quickly, accurately and consistently using Loopr's AI software and off-the-shelf hardware.", 
    videoSrc: 'https://www.youtube.com/embed/EE3BDZYb4A8'
  }, 
  problemsSection: {
    heading: 'Problems with manual defect detection', 
    content: [
      {
        title: ['Human Errors'], 
        text: 'Monotonous visual inspection causes operator fatigue and errors', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/human-error-icon.png', 
      },
      {
        title: ['Expensive Process'], 
        text: 'Difficult to scale and hire enough operators to inspect all parts and materials', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/expensive-icon.png', 
      },
      {
        title: ['Labor Risks'], 
        text: 'Susceptibility to labor shortage and knowledge loss from employee turnover', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/labor-risk-icon.png', 
      }
    ]   
  }, 
  outcomesSection: {
    content: [
      {
        title: 'Lower costs', 
        text: 'Reduce cost of quality by lowering error rate and removing redundancy checks', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/lower-cost-icon.png', 
        imgDimensions: {
          width: '3.72rem', 
          height: '3.48rem'
        }
      },
      {
        title: '100% inspection', 
        text: 'Fast software assisted checks make it feasible to visually inspect all parts', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/inspection-icon.png', 
        imgDimensions: {
          width: '3.18rem', 
          height: '3.48rem'
        }
      },
      {
        title: 'Skill retention', 
        text: 'Inspection know-how is preserved in software even if employees leave', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/skill-retention-icon.png', 
        imgDimensions: {
          width: '4.25rem', 
          height: '3.48rem'
        }
      },
    ]
  },
  automateSection: {
    heading: ['Automate quality checks with', ' AI-assisted defect detection'], 
    content: [
      {
        id: '01', 
        title: 'Customized for your parts', 
        text: [
          "LooprIQ Inspect's camera-based software is configured to identify the exact defects or anomalies which occur in your material.",
          'Detect scratches, cracks, bent tips, incomplete weld, foreign objects and anything else which a human inspector can identify.'
        ], 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-inspect-content-01.png'
      },
      {
        id: '02', 
        title: 'Run in Auto-Pilot or Co-Pilot mode', 
        text: [
          'Defects are identified in real-time, logged in a database and communicated to PLC systems for fully automated operation.',
          "Run in co-pilot mode to enhance and improve human-in-the-loop inspections."
        ], 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-inspect-content-02.png'
      },
      {
        id: '03', 
        title: 'Knowledge store', 
        text: [
          'Get a traceable and permanent database of part inspections, defects, processes and know-how.',
          'Use the database to track, analyze, root cause failures and even train your custom Large Language Models (LLMs).'
        ], 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-inspect-content-03.png'
      },
    ]
  }, 
  seeItInActionSection: false, 
  industries: ['General Manufacturing', 'Automotive', 'Mining'], 
  bannerImages: [
    'https://looprdevstorage.blob.core.windows.net/website-temp/sandvik-logo', 
    'https://looprdevstorage.blob.core.windows.net/website-temp/land-metek-logo', 
    // 'https://looprdevstorage.blob.core.windows.net/website-temp/standard-logo'
  ]
}

export default function LooprIQInspectPage() {
  return (
    <SolutionPage data={data} />
  )
}